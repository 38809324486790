import React, { useState, useEffect, createContext } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Switch from '@mui/material/Switch';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setGlobalState, useGlobalState } from "../state";
import axios from "axios";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function CAFWaitTimeThresholdMin() {

    const [switch_label, set_switch_label] = React.useState('');
    const [editMode, setEditMode] = useGlobalState('editMode')
    const [caf_wait_time_mins, set_caf_wait_time_mins] = useGlobalState('caf_wait_time_mins')
    const [in_queue_rollover_enabled, set_in_queue_rollover_enabled] = useGlobalState('in_queue_rollover_enabled')
    const [caf_wait_time_threshold_min_body, set_caf_wait_time_threshold_min_body] = useGlobalState('caf_wait_time_threshold_min_body')
    

    const handleSliderChange = (event, newValue) => {
        set_caf_wait_time_mins(event.target.value);
        caf_wait_time_threshold_min_body.originalValues["wait_time_threshold_minutes"] = caf_wait_time_mins
        caf_wait_time_threshold_min_body.newValues["data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.wait_time_threshold_minutes"] = event.target.value
        caf_wait_time_threshold_min_body.updateFlag = true;
    };
  
    const handleInputChange = (event) => {
        set_caf_wait_time_mins(event.target.value === '' ? 0 : Number(event.target.value));
        caf_wait_time_threshold_min_body.originalValues["wait_time_threshold_minutes"] = caf_wait_time_mins
        caf_wait_time_threshold_min_body.newValues["data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.wait_time_threshold_minutes"] = event.target.value
        caf_wait_time_threshold_min_body.updateFlag = true;
    };

    const handleSwitchChange = (event) => {
        set_in_queue_rollover_enabled(event.target.checked)
        caf_wait_time_threshold_min_body.originalValues["in_queue_rollover_enabled"] = in_queue_rollover_enabled
        caf_wait_time_threshold_min_body.newValues["data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.enabled"] = event.target.checked
        caf_wait_time_threshold_min_body.updateFlag = true;
      };
  
    const handleBlur = () => {
      if (caf_wait_time_mins < 0) {
        set_caf_wait_time_mins(0);
      } else if (caf_wait_time_mins > 100) {
        set_caf_wait_time_mins(100);
      }
    };

    useEffect(() => {
        getCafWaitTimeMins()
      },[]);

    useEffect(() => {
    if(in_queue_rollover_enabled){
        set_switch_label('In Queue Rollover Enabled')
    } else if (!in_queue_rollover_enabled){
        set_switch_label('In Queue Rollover Disabled')
    }
    });

    async function getCafWaitTimeMins (){
        let params = {name:'ivr_caf_routing_general'}
        let caf_wait_time_data = await axios.get('/api/az_func_im_config', {params})
        set_caf_wait_time_mins(caf_wait_time_data.data.data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.wait_time_threshold_minutes)
        set_in_queue_rollover_enabled(caf_wait_time_data.data.data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.enabled)
    }
 

    return ( 
        <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Wait Time Threshold Minutes
            </Typography>
            <Tooltip
              title="CAF Loan numbers that are excluded from the dialer list no longer fits the dialer requirements for a phone call. "
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails sx={{ width: "100%" }}>
          <Grid item xs={12} sx={{width: '100%', marginBottom: '.5%'}}>
          <Typography>{switch_label}<Switch checked={in_queue_rollover_enabled} disabled={!editMode} onChange={handleSwitchChange} inputProps={{ 'aria-label': 'controlled' }}/></Typography>  
          </Grid>
        <Grid item xs={12} sx={{width: '100%'}}>
        <Typography id="input-slider" gutterBottom>
          Wait Time Threshold Minutes: <Input
              value={caf_wait_time_mins}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 5,
                min: 0,
                max: 60,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
        </Typography>
          </Grid>  
          <Grid item xs={12}>
          <Slider
              value={caf_wait_time_mins}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              defaultValue={caf_wait_time_mins}
              disabled={!editMode}
              step={5}
              min={0}
              max={60}
            />
          </Grid>
          </AccordionDetails>
        </Accordion>
          </Grid>
        </Grid>
      </Box> 
      </Grid>
    )
}
